import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import { getCredentials } from "auth/credentials";

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const [isLoggedIn, setIsLoggedIn] = useLocalStorageState("isLoggedIn", {
    defaultValue: false,
  });
  const [profileComplete] = useLocalStorageState("profileComplete", {
    defaultValue: true,
  });
  const credentials = getCredentials();
  if (!credentials) {
    setIsLoggedIn(false);
  }

  if (!isLoggedIn || !credentials) {
    return <Navigate to="/signin" replace />;
  }

  if (isLoggedIn && !profileComplete) {
    return <Navigate to="/profile-setup" replace />;
  }

  return children;
};

export default ProtectedRoute;
