import React, { Suspense, useEffect, lazy } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UnprotectedRoute from "./UnprotectedRoute";
import ProfileSetupRoute from "./ProfileSetupRoute";
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { unprotectedRoutes } from "routes";
import ToastContainer from "common/toasts/ToastContainer";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const loading = <div></div>;

const MainLayout = lazy(async () => await import("./layout/mainLayout"));
const ProfileSetup = lazy(async () => await import("./pages/profileSetup"));

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location.pathname]);

  return (
    <>
      <ToastContainer />
      <Suspense fallback={loading}>
        <Routes>
          {unprotectedRoutes?.map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <UnprotectedRoute>
                  <Component />
                </UnprotectedRoute>
              }
            />
          ))}
          <Route
            path="/profile-setup"
            element={
              <ProfileSetupRoute>
                <ProfileSetup />
              </ProfileSetupRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
