import { getCredentials } from "auth/credentials";
import React, { ReactElement, useEffect } from "react";
import { Navigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

const ProfileSetupRoute = ({ children }: { children: ReactElement }) => {
  const [isLoggedIn] = useLocalStorageState("isLoggedIn", {
    defaultValue: false,
  });
  const [profileComplete] = useLocalStorageState("profileComplete", {
    defaultValue: false,
  });
  const credentials = getCredentials();

  useEffect(() => {
    if (isLoggedIn && profileComplete) {
      <Navigate to="/" replace />;
    }
  }, [isLoggedIn, profileComplete]);

  if (!isLoggedIn || !credentials?.token) {
    return <Navigate to="/signin" replace />;
  }

  if (isLoggedIn && profileComplete) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProfileSetupRoute;
