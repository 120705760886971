import { lazy } from "react";

// Routes that can see only logged-out users
const Login = lazy(async () => await import("pages/login"));
const ForgotPassword = lazy(async () => await import("pages/forgotPassword"));
const ResetPassword = lazy(async () => await import("pages/resetPassword"));
const LoginChallenge = lazy(async () => await import("pages/loginChallenge"));

export const unprotectedRoutes = [
  { path: "/signin", name: "Login", Component: Login },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    Component: ForgotPassword,
  },
  { path: "/reset-password", name: "ResetPassword", Component: ResetPassword },
  {
    path: "/signin-challenge",
    name: "LoginChallenge",
    Component: LoginChallenge,
  },
];

// Routes that can see only logged-in users
const AddEmployee = lazy(async () => await import("pages/addEmployee"));
const EmployeeList = lazy(async () => await import("pages/employeeList"));
const MyProfile = lazy(async () => await import("pages/myProfile"));
const Dashboard = lazy(async () => await import("pages/dashboard"));
const Payslips = lazy(async () => await import("pages/payslips"));
const PublicProfile = lazy(async () => await import("pages/publicProfile"));
const LeaveHistory = lazy(async () => await import("pages/leaveHistory"));
const BookTimeAway = lazy(async () => await import("pages/bookTimeAway"));
const AccountSettings = lazy(async () => await import("pages/accountSettings"));
const LeaveRequests = lazy(async () => await import("pages/leaveRequests"));
const LeaveBalances = lazy(async () => await import("pages/leaveBalances"));
const OrganisationChart = lazy(
  async () => await import("pages/organisationChart")
);
const Documents = lazy(async () => await import("pages/documents"));
const Payrolls = lazy(async () => await import("pages/payrolls"));
const Payroll = lazy(async () => await import("pages/payroll"));
const Calendar = lazy(async () => await import("pages/calendar"));
const Holidays = lazy(async () => await import("pages/holidays"));
const CostCentre = lazy(async () => await import("pages/costCentre"));
const ClaimHistory = lazy(async () => await import("pages/claimHistory"));
const ClaimRequests = lazy(async () => await import("pages/claimRequests"));
const SubmitClaim = lazy(async () => await import("pages/submitClaim"));
const CreatePayroll = lazy(async () => await import("pages/createPayroll"));
const LeaveAccrual = lazy(async () => await import("pages/leaveAccrual"));
const GratuityAccrual = lazy(async () => await import("pages/gratuityAccrual"));
const CompanyProfile = lazy(async () => await import("pages/companyProfile"));

export const protectedRoutes = [
  { path: "/add-employee", name: "AddEmployee", element: AddEmployee },
  { path: "/employees", name: "EmployeeList", element: EmployeeList },
  { path: "/my-profile", name: "MyProfile", element: MyProfile },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/payslips", name: "Payslips", element: Payslips },
  { path: "/profile/:id", name: "PublicProfile", element: PublicProfile },
  { path: "/leave-history", name: "LeaveHistory", element: LeaveHistory },
  { path: "/book-time-away", name: "BookTimeAway", element: BookTimeAway },
  {
    path: "/account-settings",
    name: "AccountSettings",
    element: AccountSettings,
  },
  { path: "/leave-requests", name: "LeaveRequests", element: LeaveRequests },
  { path: "/leave-balances", name: "LeaveBalances", element: LeaveBalances },
  {
    path: "/organisation-chart",
    name: "OrganisationChart",
    element: OrganisationChart,
  },
  { path: "/documents", name: "Documents", element: Documents },
  { path: "/payrolls", name: "Payrolls", element: Payrolls },
  { path: "/payroll/:id", name: "Payroll", element: Payroll },
  { path: "/calendar", name: "Calendar", element: Calendar },
  { path: "/holidays", name: "Holidays", element: Holidays },
  { path: "/cost-centre", name: "CostCentre", element: CostCentre },
  { path: "/claim-history", name: "ClaimHistory", element: ClaimHistory },
  { path: "/claim-requests", name: "ClaimRequests", element: ClaimRequests },
  { path: "/submit-claim", name: "SubmitClaim", element: SubmitClaim },
  { path: "/create-payroll", name: "CreatePayroll", element: CreatePayroll },
  { path: "/leave-accrual", name: "LeaveAccrual", element: LeaveAccrual },
  {
    path: "/gratuity-accrual",
    name: "GratuityAccrual",
    element: GratuityAccrual,
  },
  { path: "/company-profile", name: "CompanyProfile", element: CompanyProfile },
];
