import { Credentials } from "interfaces";

export function setCredentials(credentials: Credentials | null): void {
  const credentialsJson = JSON.stringify(credentials);
  localStorage.setItem("credentials", credentialsJson);
}

export function getCredentials(): Credentials | null {
  const credentials = localStorage.getItem("credentials");
  if (!credentials) {
    return null;
  }

  const { userId, companyId, token, language, location_id }: Credentials =
    JSON.parse(credentials);
  return {
    userId,
    companyId,
    token,
    language,
    location_id,
  };
}

export function deleteCredentials(): void {
  localStorage.removeItem("credentials");
}
