import { getCredentials } from "auth/credentials";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";

const UnprotectedRoute = ({ children }: { children: ReactElement }) => {
  const [isLoggedIn] = useLocalStorageState("isLoggedIn", {
    defaultValue: false,
  });
  const [profileComplete] = useLocalStorageState("profileComplete", {
    defaultValue: true,
  });
  const credentials = getCredentials();

  if (isLoggedIn && credentials?.token) {
    if (profileComplete) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/profile-setup" replace />;
    }
  }

  return children;
};

export default UnprotectedRoute;
